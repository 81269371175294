import {
  Database,
  SharepointFileSelectionMap,
  SupportedFileType,
  supportedFileTypes,
  zeros,
} from 'common-ts';
import { FolderBreadCrumbs, isFileSelectable } from './utils';
import { HStack, IconButton } from '@chakra-ui/react';
import {
  PaginationItems,
  PaginationNextTrigger,
  PaginationPrevTrigger,
  PaginationRoot,
} from '@/components/ui/pagination';
import {
  faAngleRight,
  faArrowLeft,
  faFile,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useRef, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox';
import FileIcon from '../../../components/FileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../components/ListItem';
import SharepointBreadCrumbs from './SharepointBreadCrumbs';
import { Skeleton } from '@/components/ui/skeleton';
import { Tooltip } from '@/components/ui/tooltip';
import { debounce } from 'lodash';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

export type BasicFileInfo = {
  id: string;
  name: string;
  embeddingStatus: Database['public']['Enums']['embedding_status'];
  mimeType: string;
};

export type BasicFolderInfo = {
  id: string;
  name: string;
  childFolderCount?: number;
  fileCount?: number;
};

type GenericSelectableFileAndFolderListProps = {
  className?: string;
  files: BasicFileInfo[];
  folders: BasicFolderInfo[];
  loading: boolean;
  hideCounts?: boolean;
  breadCrumbs: FolderBreadCrumbs;
  selectedFileMap: SharepointFileSelectionMap[string]['folders'];
  overallFileCount: number;
  onBackClick: () => void;
  onFolderClick: (params: { folderId: string; name: string }) => void;
  onRefreshClick: () => void;
  onSelectAllClick: () => void;
  onDeselectAllClick: () => void;
  onFileCheckboxClick: (fileId: string) => void;
  onFolderCheckboxClick: (folderId: string) => void;
  onSelectFolderInCrumbs: (index: number) => void;
  folderMapPathFromBreadCrumbs: (breadCrumbs: FolderBreadCrumbs) => string;
  // onBackFromRootFolderClick: () => void;
};

/**
 * Minimum number of items to display per page regardless of container size
 */
const MIN_ITEMS_PER_PAGE = 6;

/**
 * Maximum number of items to display per page regardless of container size
 */
const MAX_ITEMS_PER_PAGE = 40;

/**
 * Time in milliseconds to wait before recalculating items per page after resize
 */
const DEBOUNCE_TIME_MS = 150;

/**
 * Component for traversing and selecting folders and files.
 */
function GenericSelectableFileAndFolderList({
  className,
  files,
  folders,
  loading,
  hideCounts = false,
  breadCrumbs,
  selectedFileMap,
  overallFileCount,
  onBackClick,
  onFolderClick,
  onRefreshClick,
  onSelectAllClick,
  onDeselectAllClick,
  onFileCheckboxClick,
  onFolderCheckboxClick,
  onSelectFolderInCrumbs,
  folderMapPathFromBreadCrumbs,
  // onBackFromRootFolderClick,
}: GenericSelectableFileAndFolderListProps) {
  const { t } = useTranslation();
  const csv = useBoundStore((state) => state.featureFlags?.csv);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number | null>(null);

  // Refs for measuring container elements
  const containerRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  // Calculate pagination indices
  const startIndex = (currentPage - 1) * (itemsPerPage || 0);
  const endIndex = startIndex + (itemsPerPage || 0);
  const totalItems = folders.length + files.length;

  // State for paginated content
  const [paginatedFolders, setPaginatedFolders] = useState<typeof folders>([]);
  const [paginatedFiles, setPaginatedFiles] = useState<typeof files>([]);

  /**
   * Calculates the number of items that can fit in the container
   * based on container height and item height
   */
  function calculateItemsPerPage() {
    const container = containerRef.current;
    const pagination = paginationRef.current;
    const breadcrumbs = breadcrumbsRef.current;

    if (container && pagination && breadcrumbs) {
      const containerHeight =
        container.clientHeight -
        pagination.clientHeight -
        breadcrumbs.clientHeight;
      const itemHeight = 40;

      const itemsPerPage = Math.floor(containerHeight / itemHeight) - 2;

      // Ensure items per page is within bounds and return calculated value
      return itemsPerPage > MIN_ITEMS_PER_PAGE
        ? itemsPerPage < MAX_ITEMS_PER_PAGE
          ? itemsPerPage
          : MAX_ITEMS_PER_PAGE
        : MIN_ITEMS_PER_PAGE;
    }
    return MIN_ITEMS_PER_PAGE;
  }

  /**
   * Updates the paginated content based on current page and items per page
   */
  useEffect(() => {
    if (itemsPerPage === null) return;

    const availableSlots = endIndex - startIndex;

    // Calculate folders pagination
    const foldersStartIndex = Math.min(startIndex, folders.length);
    const foldersEndIndex = Math.min(
      startIndex + availableSlots,
      folders.length
    );
    const foldersToShow = foldersEndIndex - foldersStartIndex;

    // Calculate files pagination
    const filesStartIndex = Math.max(0, startIndex - folders.length);
    const filesEndIndex = Math.min(
      files.length,
      filesStartIndex + (availableSlots - foldersToShow)
    );

    setPaginatedFolders(folders.slice(foldersStartIndex, foldersEndIndex));
    setPaginatedFiles(files.slice(filesStartIndex, filesEndIndex));
  }, [folders, files, currentPage, itemsPerPage, startIndex, endIndex]);

  /**
   * Handles container resizing and updates items per page
   */
  useEffect(() => {
    const debouncedResize = debounce(() => {
      if (itemsPerPage !== null) {
        setItemsPerPage(calculateItemsPerPage());
      }
    }, DEBOUNCE_TIME_MS);

    const handleRefChanges = debounce(() => {
      const calculatedItemsPerPage = calculateItemsPerPage();
      setItemsPerPage(calculatedItemsPerPage);
    }, DEBOUNCE_TIME_MS);

    handleRefChanges();

    // Set up resize observers
    window.addEventListener('resize', debouncedResize);
    const resizeObserver = new ResizeObserver(() => {
      if (itemsPerPage !== null) {
        setItemsPerPage(calculateItemsPerPage());
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      window.removeEventListener('resize', debouncedResize);
      resizeObserver.disconnect();
      debouncedResize.cancel();
      handleRefChanges.cancel();
    };
  }, [files, folders]);

  /**
   * Handles page change from pagination component
   * @param {Object} details - Page change event details
   * @param {number} details.page - New page number
   */
  function handlePaginationPageChange({
    page,
  }: {
    page: number;
    pageSize: number;
  }) {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }

  return (
    <div className={twMerge('flex h-full flex-col gap-2 px-4 py-2', className)}>
      {/* ---------------------------------------------------------------- Head ---------------------------------------------------------------- */}
      <div className="flex items-center justify-between">
        <div className="flex items-baseline gap-2">
          <div className="text-chakra-gray-500">{`${overallFileCount} ${t(
            'fileManagerPanel.totalFiles'
          )}`}</div>
          <IconButton
            aria-label="Refresh file list"
            variant={'ghost'}
            size={'xs'}
            onClick={onRefreshClick}
          >
            <FontAwesomeIcon
              icon={faRotateRight}
              className="text-chakra-gray-600 text-xs"
            />
          </IconButton>
        </div>
        {files.length || folders.length ? (
          <div className="flex items-center gap-2">
            <div
              className="text-maia-accent cursor-pointer select-none text-xs"
              onClick={() => {
                onSelectAllClick();
              }}
            >
              {t('general.selectAll')}
            </div>
            <div
              className="text-maia-support-red cursor-pointer select-none text-xs"
              onClick={() => {
                onDeselectAllClick();
              }}
            >
              {t('general.deselectAll')}
            </div>
          </div>
        ) : null}
      </div>
      {/* ----------------------------------------------------- Breadcrumbs ------------------------------*/}
      <div className="flex items-center gap-2" ref={breadcrumbsRef}>
        <IconButton
          className={`${breadCrumbs.length === 0 ? 'md:hidden' : ''}`}
          aria-label="back"
          variant="ghost"
          onClick={() => {
            setCurrentPage(1);
            onBackClick();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <SharepointBreadCrumbs
          className="flex-grow"
          folders={breadCrumbs}
          onFolderSelect={onSelectFolderInCrumbs}
        />
        {!hideCounts && (
          <div
            className={twMerge(
              `text-chakra-gray-400 mr-[30px] flex items-center gap-1`,
              breadCrumbs.length !== 0 ? 'mr-[42px]' : ''
            )}
          >
            <FontAwesomeIcon icon={faFolder} />|
            <FontAwesomeIcon icon={faFile} />
          </div>
        )}
      </div>

      {/* --------------------------------------------------- Pagination -------------------------------------------- */}
      {!loading && totalItems > 0 && (
        <PaginationRoot
          count={totalItems}
          pageSize={itemsPerPage || 1}
          defaultPage={1}
          size={'xs'}
          siblingCount={3}
          onPageChange={handlePaginationPageChange}
        >
          <HStack
            wrap="wrap"
            className="w-full justify-start py-2"
            ref={paginationRef}
          >
            <PaginationPrevTrigger />
            <PaginationItems />
            <PaginationNextTrigger />
          </HStack>
        </PaginationRoot>
      )}
      <div
        ref={containerRef}
        className="flex flex-grow flex-col gap-2 overflow-y-auto"
      >
        {/* ------------------------------------------------ Loading ------------------------------------------------ */}
        {loading ? (
          zeros(5).map((_, index) => {
            return (
              <Skeleton key={index}>
                <ListItem selected={false}>Loading</ListItem>
              </Skeleton>
            );
          })
        ) : (
          <div className="flex flex-col gap-2">
            {/* ---------------------------------------------------------------- Folders ---------------------------------------------------------------- */}
            {paginatedFolders.map((folder) => {
              const checkedStatus =
                selectedFileMap[
                  folderMapPathFromBreadCrumbs([
                    ...breadCrumbs,
                    { id: folder.id ?? folder.name, name: folder.name },
                  ])
                ]?.selected;

              return (
                <div key={folder.id ?? folder.name}>
                  <ListItem
                    key={folder.id ?? folder.name}
                    selected={false}
                    className="flex h-10 flex-shrink-0 cursor-pointer items-center gap-2 px-4"
                  >
                    <Checkbox
                      colorPalette="maia-purple"
                      className="hover:bg-maia-purple-50 rounded"
                      checked={
                        checkedStatus === null ? 'indeterminate' : checkedStatus
                      }
                      // It is very important that clicking on this Checkbox does not trigger folder navigation (at least not before handling the onChange)
                      // Otherwise, the breadcrumbs will not match anymore once the onChange event is handled
                      // Sadly, stopPropagation on this Checkboxes onClick event does not have the desired effect, so we can't use the ListItems onClick event for folder navigation.
                      onCheckedChange={() => {
                        onFolderCheckboxClick(folder.id ?? folder.name);
                      }}
                    />
                    {/* The div hierarchy is a bit awkward here in order to separate the checkbox from the rest of the Listitem's content because of the onClick problem mentioned above */}
                    <div
                      className="flex flex-grow items-center justify-between"
                      onClick={() => {
                        onFolderClick({
                          folderId: folder.id ?? folder.name,
                          name: folder.name,
                        });
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="text-chakra-gray-400 text-base"
                        />
                        <Tooltip content={folder.name} openDelay={150}>
                          <span className="max-w-96 truncate text-sm 2xl:max-w-none">
                            {folder.name}
                          </span>
                        </Tooltip>
                      </div>
                      <div className="text-chakra-gray-500 flex items-center gap-1">
                        {!hideCounts ? (
                          <>
                            <div>{folder.childFolderCount}</div> |
                            <div>{folder.fileCount}</div>
                          </>
                        ) : null}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="text-xs"
                        />
                      </div>
                    </div>
                  </ListItem>
                </div>
              );
            })}
            {/* ---------------------------------------------------------------- Files ---------------------------------------------------------------- */}
            {paginatedFiles.map((file) => {
              const checkedStatus =
                selectedFileMap[folderMapPathFromBreadCrumbs(breadCrumbs)]!
                  .files[file.id];

              const fileTypeSupported = supportedFileTypes.includes(
                file.mimeType as SupportedFileType
              );

              return (
                <ListItem
                  key={file.id}
                  selected={false}
                  className="flex h-10 flex-shrink-0 cursor-pointer items-center gap-2 px-4"
                >
                  <Checkbox
                    disabled={
                      !isFileSelectable({
                        csvEnabled: csv,
                        embeddingStatus: file.embeddingStatus,
                        mimeType: file.mimeType,
                      })
                    }
                    colorPalette="maia-purple"
                    className="hover:bg-maia-purple-50 rounded"
                    checked={checkedStatus}
                    onCheckedChange={() => {
                      onFileCheckboxClick(file.id);
                    }}
                  >
                    <Tooltip
                      disabled={file.embeddingStatus === 'FINISHED'}
                      content={
                        !fileTypeSupported
                          ? t('chat.fileSelector.fileTypeNotSupportedTooltip')
                          : file.embeddingStatus === 'FAILED'
                            ? t('chat.fileSelector.fileFailedTooltip')
                            : t('chat.fileSelector.fileNotProcessedTooltip')
                      }
                    >
                      <div className="flex h-10 w-72 items-center gap-2 pl-2">
                        <FileIcon
                          name={file.name}
                          status={file.embeddingStatus}
                          className="text-chakra-gray-400 text-base"
                        />
                        <Tooltip
                          content={file.name}
                          openDelay={150}
                          positioning={{ placement: 'top' }}
                        >
                          <span className="max-w-96 truncate text-sm font-normal 2xl:max-w-none">
                            {file.name}
                          </span>
                        </Tooltip>
                      </div>
                    </Tooltip>
                  </Checkbox>
                </ListItem>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default GenericSelectableFileAndFolderList;
