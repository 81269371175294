import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import {
  encodeName,
  planFileSizeLimitMbMap,
  supportedFileTypes,
} from 'common-ts';

import AdaUsage from '../../components/ada/AdaUsage.js';
import Feature from '../../components/Feature.js';
import GenericUploadModal from '../../components/GenericUploadModal.js';
import { MEGABYTE_IN_BYTES } from 'common-ts';
import { TempFileObject } from '../chat/fileSelector/useStorageSelectionStateHandlers.js';
// @ts-ignore
import pdfWorker from 'pdfjs-dist/build/pdf.worker.js';
import { toaster } from '../../components/ui/toaster.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

GlobalWorkerOptions.workerSrc = pdfWorker;

type FileUploadModalProps = {
  isOpen: boolean;
  uploadPath: string | undefined;
  collectionId: string;
  usedStorageMb: number;
  storageLimitMb: number;
  existingFileNames: string[];
  collectionCustomFileLimit?: number;
  onClose: () => void;
  onFilesStartedUploading?: (files: TempFileObject[]) => void;
  onFilesFinishedUploading?: (files: TempFileObject[]) => void;
  onFreeStorageLimitReached: () => void;
  onFreeUserMultiFileAttempt: () => void;
};

function FileUploadModal({
  isOpen,
  uploadPath,
  collectionId,
  usedStorageMb,
  storageLimitMb,
  existingFileNames,
  collectionCustomFileLimit,
  onClose,
  onFilesStartedUploading,
  onFilesFinishedUploading,
  onFreeStorageLimitReached,
  onFreeUserMultiFileAttempt,
}: FileUploadModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const csv = useBoundStore((state) => state.featureFlags?.csv);
  const maxFileSize =
    planFileSizeLimitMbMap[workspaceLicenseType ?? 'FREE'] * MEGABYTE_IN_BYTES;

  async function handleFileSelect(files: FileList | undefined) {
    if (!files || files.length === 0) {
      toaster.create({
        title: t('fileManagerPanel.fileUploadModal.noFilesSelectedWarning'),
        type: 'warning',
      });
      return;
    }
    if (workspaceLicenseType === 'FREE' && files.length > 1) {
      onFreeUserMultiFileAttempt();
      return;
    }

    let totalFileSize = 0;
    const fileList: File[] = [];
    const tooLongFiles: File[] = [];

    for (const f of files) {
      // Check for files that exceed page limit
      const fileSizeMb = f.size / MEGABYTE_IN_BYTES;
      if (f.type === 'application/pdf') {
        const pdf = await getDocument(await f.arrayBuffer()).promise;
        if (pdf.numPages > 2000) {
          tooLongFiles.push(f);
        } else {
          totalFileSize += fileSizeMb;
          fileList.push(f);
        }
      } else {
        totalFileSize += fileSizeMb;
        fileList.push(f);
      }
    }

    // Display toasts for files that are too long
    if (tooLongFiles.length > 0) {
      tooLongFiles.forEach((file) => {
        toaster.create({
          title: `${t('fileManagerPanel.uploadPageCountError', {
            filename: file.name,
            limit: '2000',
          })}`,
          type: 'error',
        });
      });
    }

    if (totalFileSize + usedStorageMb > storageLimitMb) {
      if (workspaceLicenseType === 'FREE') {
        onFreeStorageLimitReached();
      } else {
        toaster.create({
          title: t('fileManagerPanel.storageLimitReached'),
          type: 'error',
        });
      }
      return;
    }

    const tempFiles = fileList
      .map((file) => {
        const tempFile: TempFileObject = {
          objectType: 'tempFile',
          name: file.webkitRelativePath || file.name,
          type: file.type,
          status: { type: 'UPLOADING' },
          id: v4(),
        };
        return tempFile;
      })
      .filter(
        (file) =>
          !existingFileNames.some(
            (existingFileName) => existingFileName === file.name
          )
      );

    onFilesStartedUploading?.(tempFiles);

    const promises = fileList.map(async (file) => {
      // If the file is in a folder, use the folder path as the file name
      const backupName = file.webkitRelativePath || file.name;
      const { data: name, error: encodeNameError } = encodeName(backupName);
      if (encodeNameError) {
        console.error(encodeNameError);
      }
      if (file.name.endsWith('.csv')) {
        file = new File([await file.arrayBuffer()], file.name, {
          type: 'text/csv',
        });
      }

      if (uploadPath?.startsWith('/')) {
        uploadPath = uploadPath.replace('/', '');
      }
      return supabase.storage
        .from(collectionId)
        .upload(`${uploadPath}/${name || backupName}`, file);
    });

    /* Wait for all files to upload
     before calling finished uploading callback
     otherwise, it would trigger overwrite
     large files that are being uploaded would disappear */

    const responses = await Promise.all(promises);

    const err = responses[0]?.error;

    if (err) {
      toaster.create({
        title: `${t('fileManagerPanel.collectionDuplicate')}`,
        type: 'warning',
      });
    }

    onFilesFinishedUploading?.(tempFiles);
  }

  return (
    <GenericUploadModal
      type="fileUpload"
      isOpen={isOpen}
      onClose={onClose}
      acceptedMimeTypes={supportedFileTypes.filter((mimeType) => {
        if (mimeType === 'text/csv') {
          return !!csv;
        } else {
          return true;
        }
      })}
      maxFileSize={maxFileSize}
      allowMultiUpload={workspaceLicenseType !== 'FREE'}
      onFileSelect={handleFileSelect}
      existingNumberOfFiles={existingFileNames.length}
      maxNumberOfFiles={collectionCustomFileLimit}
      allowFolderUpload
    >
      <Feature name="analyze">
        <AdaUsage />
      </Feature>
    </GenericUploadModal>
  );
}

export default FileUploadModal;
