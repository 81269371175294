export var AvailableModelEnum;
(function (AvailableModelEnum) {
    AvailableModelEnum["GPT_4_O"] = "gpt-4-o";
    AvailableModelEnum["GPT_4_O_MINI"] = "gpt-4-o-mini";
})(AvailableModelEnum || (AvailableModelEnum = {}));
// In case of adding new experimental models, add them here and add relevant API keys and endpoints in experimentalLLMConfig
export var ExperimentalModelEnum;
(function (ExperimentalModelEnum) {
    ExperimentalModelEnum["MISTRAL_LARGE_2407"] = "Mistral-large-2407";
    ExperimentalModelEnum["META_LLAMA_3_3_70B_INSTRUCT"] = "Meta-Llama-3-3-70B-Instruct";
})(ExperimentalModelEnum || (ExperimentalModelEnum = {}));
export const isExperimentalModel = (model) => {
    return Object.values(ExperimentalModelEnum).includes(model);
};
const availableModelForPlan = new Map([
    ['FREE', []],
    ['BASIC', [AvailableModelEnum.GPT_4_O_MINI, AvailableModelEnum.GPT_4_O]],
    [
        'PROFESSIONAL',
        [AvailableModelEnum.GPT_4_O_MINI, AvailableModelEnum.GPT_4_O],
    ],
    ['ENTERPRISE', [AvailableModelEnum.GPT_4_O_MINI, AvailableModelEnum.GPT_4_O]],
]);
/**
 * Checks whether an llm is available for a specified license tier.
 *
 * @param plan The license tier to check the availability for.
 * @param model The llm to check the availability of.
 * @returns True if the model is available, otherwise false.
 */
export function isModelAvailableForPlan(plan, model) {
    const allowedModels = availableModelForPlan.get(plan);
    if (!allowedModels) {
        return false;
    }
    return allowedModels.includes(model);
}
